/* eslint-disable jsx-a11y/media-has-caption */
import React, { useContext } from "react"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useTranslation } from "react-i18next"
import { withTrans } from "../i18n/withTrans"

import { StaticImage } from "gatsby-plugin-image"

// Import Swiper React components

// Import Swiper styles
import "swiper/css"
import "swiper/css/bundle"
import "./home.scss"

import Fade from "react-reveal/Fade"
import Flip from "react-reveal/Flip"

import BorrowerBanner from "../components/Home/borrowerBanner"
import AppDataContext from "../contexts/AppDataContext"
import LoanFlow from "../components/Home/loanFlow"
import BorrowerStory from "../components/Home/borrowerStory"
import BorrowerTestimony from "../components/Home/borrowerTestimony"
import WeGrowAsWeGo from "../components/Home/weGrowAsWeGo"

const HomePage = () => {
  const { t, i18n } = useTranslation()

  const appDataContext = useContext(AppDataContext)

  let totalBorrower = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.totalBorrowersUsers || 0
  )
  let activeBorrower = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.activeUserCnt || 0
  )
  let totalBorrowerThisYear = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.borrowUsersOfTheYear || 0
  )

  let loanAmountThisYear = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.averageLastMonths || 0
  )
  let totalLoanAmount = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.totalFunding || 0
  )
  let outstandingLoanAmount = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.unwithdrawAmt || 0
  )

  let totalAcumulateLenders = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.investmentUsersOfAll || 0
  )
  let totalLendersThisYear = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.investmentUsersOfYear || 0
  )
  let ongoingLenders = new Intl.NumberFormat("de-DE").format(
    appDataContext.reportData?.investingUsers || 0
  )

  const borrowerData = () => {
    return [
      {
        title: t(`home.gif-1`),
        value: totalBorrower,
        textVal: `${totalBorrower} Users`,
      },
      {
        title: t(`home.gif-2`),
        value: totalBorrowerThisYear,
        textVal: `${totalBorrowerThisYear} Users`,
      },
      {
        title: t(`home.gif-3`),
        value: activeBorrower,
        textVal: `${activeBorrower} Users`,
      },
    ]
  }

  const lenderData = () => {
    return [
      {
        title: t(`home.gif-4`),
        value: totalAcumulateLenders,
        textVal: `${totalAcumulateLenders} Users`,
      },
      {
        title: t(`home.gif-5`),
        value: totalLendersThisYear,
        textVal: `${totalLendersThisYear} Users`,
      },
      {
        title: t(`home.gif-6`),
        value: ongoingLenders,
        textVal: `${ongoingLenders} Users`,
      },
    ]
  }

  const loanData = () => {
    return [
      {
        title: t(`home.gif-7`),
        value: totalLoanAmount,
        textVal: `Rp. ${totalLoanAmount}`,
      },
      {
        title: t(`home.gif-8`),
        value: loanAmountThisYear,
        textVal: `Rp. ${loanAmountThisYear}`,
      },
      {
        title: t(`home.gif-9`),
        value: outstandingLoanAmount,
        textVal: `Rp. ${outstandingLoanAmount}`,
      },
    ]
  }

  const theme = useTheme()
  const isMobileSmall = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Layout>
      <Seo id={"home"} title={t(`menus.borrower`)} />
      {/* Banner- Only On Asetku */}
      <div className="banner-only-asetku">
        <BorrowerBanner />
        <div className="only-on-asetku">
          <StaticImage
            src="../assets/images/decoration/only-on-asetku-arrow.png"
            alt="decoration"
            className="arrow"
          />
          <Container>
            {/* Only on Asetku */}
            <Grid container className="section-container">
              <Fade left duration={500}>
                <Grid item md={12} className="section-title">
                  {t(`home.only-on-asetku`)}
                </Grid>
              </Fade>
              <div className="content">
                <Flip right duration={1000}>
                  <OnlyOnAsetku
                    title={t(`home.only-on-asetku-1-title`)}
                    desc={t(`home.only-on-asetku-1-description`)}
                  />
                </Flip>
                <Flip right duration={1500} delay={500}>
                  <OnlyOnAsetku
                    title={t(`home.only-on-asetku-2-title`)}
                    desc={t(`home.only-on-asetku-2-description`)}
                  />
                </Flip>
                <Flip right duration={1500} delay={1000}>
                  <OnlyOnAsetku
                    title={t(`home.only-on-asetku-3-title`)}
                    desc={t(`home.only-on-asetku-3-description`)}
                  />
                </Flip>
                <Flip right duration={1500} delay={1500}>
                  <OnlyOnAsetku
                    title={t(`home.only-on-asetku-4-title`)}
                    desc={t(`home.only-on-asetku-4-description`)}
                  />
                </Flip>
              </div>
            </Grid>
          </Container>
        </div>
      </div>
      {/* we grow as we go */}

      <WeGrowAsWeGo
        borrowerData={borrowerData()}
        loanData={loanData()}
        lenderData={lenderData()}
      />

      {/* Loan Flow */}
      <LoanFlow />

      <BorrowerTestimony />

      {/* Asetku Lenders Story */}
      <BorrowerStory />
      {/* Member Of, Mitra Asetku */}
      <Container>
        <div className="member-mitra">
          <div className="content">
            <div className="member">
              <h1>{t(`home.member-of`)} :</h1>
              <div className="member-list">
                <Fade left>
                  <StaticImage
                    className="member-mitra-logo"
                    src={"../assets/images/logos/Member/aftech.png"}
                    alt=""
                  />
                </Fade>
                <div className="member-row-1">
                  <Fade left>
                    <StaticImage
                      className="member-mitra-logo"
                      src={"../assets/images/logos/Member/afpi-gabung.png"}
                      alt=""
                    />
                  </Fade>
                </div>
                <div className="member-row-2">
                  <Fade left>
                    <StaticImage
                      className="member-mitra-logo"
                      src={"../assets/images/logos/Member/kominfo.png"}
                      alt=""
                    />
                  </Fade>
                  <Fade left>
                    <StaticImage
                      className="member-mitra-logo"
                      src={"../assets/images/logos/Member/iso.png"}
                      alt=""
                    />
                  </Fade>
                </div>
                <StaticImage
                  src="../assets/images/decoration/mitra-dot.svg"
                  className="mitra-dot"
                  alt=""
                />
              </div>
            </div>
            <div className="mitra">
              <h1>{t(`home.mitra-asetku`)} :</h1>
              <div className="mitra-list">
                {isMobileSmall ? mobileMitraContent() : desktopMitraContent()}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

function OnlyOnAsetku({ title, desc }) {
  return (
    <div className="content-list">
      <span className="title">{title}</span>
      <span className="description">{desc}</span>
    </div>
  )
}

const desktopMitraContent = () => {
  return (
    <>
      <div className="mitra-row-1">
        <Fade left>
          <StaticImage
            className="member-mitra-logo-digisign"
            src={"../assets/images/logos/Mitra/digisign.png"}
            alt=""
          />
        </Fade>
        <Fade left>
          <StaticImage
            className="member-mitra-logo-intra-asia"
            src={"../assets/images/logos/Mitra/intra-asia.png"}
            alt=""
          />
        </Fade>
        <Fade left>
          <StaticImage
            className="member-mitra-logo-pefindo"
            src={"../assets/images/logos/Mitra/pefindo.png"}
            alt=""
          />
        </Fade>
        <Fade left>
          <StaticImage
            className="member-mitra-logo-sss"
            src={"../assets/images/logos/Mitra/sss.jpg"}
            alt=""
          />
        </Fade>
      </div>
      <div className="mitra-row-2">
        <Fade left>
          <StaticImage
            className="member-mitra-logo-mandiri"
            src={"../assets/images/logos/Mitra/mandiri.png"}
            alt=""
          />
        </Fade>
        <Fade left>
          <StaticImage
            className="member-mitra-logo-telmark"
            src={"../assets/images/logos/Mitra/telmark.png"}
            alt=""
          />
        </Fade>
        <Fade left>
          <StaticImage
            className="member-mitra-logo-bca"
            src={"../assets/images/logos/Mitra/bca.png"}
            alt=""
          />
        </Fade>
        <Fade left>
          <StaticImage
            className="member-mitra-logo-bni"
            src={"../assets/images/logos/Mitra/bni.png"}
            alt=""
          />
        </Fade>
      </div>
      <div className="mitra-row-3">
        <Fade left>
          <StaticImage
            className="member-mitra-logo-bri"
            src={"../assets/images/logos/Mitra/bri.png"}
            alt=""
          />
        </Fade>
        <Fade left>
          <StaticImage
            className="member-mitra-logo-cimb"
            src={"../assets/images/logos/Mitra/cimb.png"}
            alt=""
          />
        </Fade>
        <Fade left>
          <StaticImage
            className="member-mitra-logo-vida"
            src={"../assets/images/logos/Mitra/vida.png"}
            alt=""
          />
        </Fade>
      </div>
    </>
  )
}

const mobileMitraContent = () => {
  return (
    <>
      <div className="mitra-row-1" style={{ marginBottom: 10 }}>
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/digisign.png"}
          alt=""
          style={{ height: "auto" }}
        />
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/intra-asia.png"}
          alt=""
          style={{ height: "auto" }}
        />
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/pefindo.png"}
          alt=""
          style={{ height: "auto" }}
        />
      </div>
      <div className="mitra-row-2" style={{ marginBottom: 10 }}>
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/sss.jpg"}
          alt=""
          style={{ height: "auto" }}
        />
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/telmark.png"}
          alt=""
          style={{ height: "auto" }}
        />
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/bca.png"}
          alt=""
          style={{ height: "auto" }}
        />
      </div>
      <div className="mitra-row-3" style={{ marginBottom: 10 }}>
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/bni.png"}
          alt=""
          style={{ height: "auto" }}
        />
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/mandiri.png"}
          alt=""
          style={{ height: "auto" }}
        />
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/bri.png"}
          alt=""
          style={{ height: "auto" }}
        />
      </div>
      <div className="mitra-row-4">
        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/cimb.png"}
          alt=""
          style={{ height: "auto" }}
        />

        <StaticImage
          className="member-mitra-logo"
          src={"../assets/images/logos/Mitra/vida.png"}
          alt=""
          style={{ height: "auto" }}
        />
      </div>
    </>
  )
}

export default withTrans(HomePage)
