import React, { useState } from "react"
import "./borrowerBanner.scss"
import LoanCalculator from "../LoanCalculator/loanCalculator"
import { useTranslation } from "react-i18next"
import bannerId from "../../assets/images/borrower/banner-text-id.png"
import bannerEn from "../../assets/images/borrower/banner-text-en.png"
import { Grid, useMediaQuery, useTheme } from "@material-ui/core"
import coin1 from "../../assets/images/borrower/coins-1.png"
import coin2 from "../../assets/images/borrower/coins-2.png"
import coinMobile from "../../assets/images/borrower/coin-mobile.png"
import BannerISOButton from "./isoButton"
import { StaticImage } from "gatsby-plugin-image"

import iso27701 from "../../assets/Certificate_ISO_27701-SAC-PT_Pintar_Inovasi_Digital.pdf"
import iso27001 from "../../assets/Certificate_ISO_27001-SAC-PT_Pintar_Inovasi_Digital.pdf"

function BorrowerBanner() {
  const { t, i18n } = useTranslation()

  const theme = useTheme()
  const isMobileXS = useMediaQuery(theme.breakpoints.down(780))
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"))
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))

  const [coin1ClassName, setCoin1Classname] = useState("img-coin coin-1")
  const [coin2ClassName, setCoin2Classname] = useState("img-coin coin-2")
  const [coinMobileClassName, setCoinMobileClassname] = useState(
    "img-coin mobile"
  )

  return (
    <div className="borrower-banner">
      {!isMobile ? (
        <img
          src={coinMobile}
          className={coinMobileClassName}
          onAnimationEnd={() => {
            setCoinMobileClassname("img-coin mobile-active")
          }}
          alt=""
        />
      ) : (
        <>
          <img
            src={coin1}
            className={coin1ClassName}
            onAnimationEnd={() => {
              setCoin1Classname("img-coin coin-1-active")
            }}
            alt=""
          />
          <img
            src={coin2}
            className={coin2ClassName}
            onAnimationEnd={() => {
              setCoin2Classname("img-coin coin-2-active")
            }}
            alt=""
          />
        </>
      )}

      <Grid container>
        <Grid item lg={8} md={12} sm={12} xs={12} className="banner-text">
          <img
            src={i18n.language === "id" ? bannerId : bannerEn}
            alt="easy-loan"
            className="banner-text-img"
          />
          <p>{t("home.banner.title-desc")}</p>
          {isDesktop ? (
            <div
              style={{
                position: "absolute",
                bottom: "-8%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "30px",
              }}
            >
              <BannerISOButton
                smallSize={false}
                title={"Asetku Certificate ISO 27001:2013"}
                fileUrl={iso27001}
                img={
                  <StaticImage
                    src={"../../assets/images/ISO27001.png"}
                    alt=""
                    width={90}
                  />
                }
              />
              <BannerISOButton
                smallSize={false}
                fileUrl={iso27701}
                title={"Asetku Certificate ISO 27701:2019"}
                img={
                  <StaticImage
                    src={"../../assets/images/ISO27701.png"}
                    alt=""
                    width={90}
                  />
                }
              />
            </div>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item lg={4} md={12} sm={12} xs={12} className="calculator">
          <LoanCalculator />
        </Grid>
        {!isDesktop ? (
          <Grid item lg={4} md={12} sm={12} xs={12} className="iso-button">
            <div
              style={{
                position: "relative",
                bottom: "-45%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <BannerISOButton
                smallSize={isMobileXS}
                fileUrl={iso27001}
                title={"Asetku Certificate ISO 27001:2013"}
                img={
                  <StaticImage
                    src={"../../assets/images/ISO27001.png"}
                    alt=""
                    width={60}
                  />
                }
              />
              <BannerISOButton
                smallSize={isMobileXS}
                fileUrl={iso27701}
                title={"Asetku Certificate ISO 27701:2019"}
                img={
                  <StaticImage
                    src={"../../assets/images/ISO27701.png"}
                    alt=""
                    width={60}
                  />
                }
              />
            </div>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  )
}

export default BorrowerBanner
